<template>
<div>
  	<div v-if="items.length">
      <div
        @mouseover="isMouseOver  = true"
        @mouseleave="isMouseOver = false"
        v-for="(item, idx) in orderedItems"
        class="project"
        :key="item.id"
        :class="{ selected: selectedIndex === idx }"
        :href="item.link"
      >
      <a :href="item.link">
      <div class="project-details">
        <div v-if="!logo_only">
          <div class="details-wrapper" :href="item.link">
            <div class='title'><h2>{{ item.title }}</h2></div>
            <!-- <div><h4>PROJECT VALUE</h4></div>
            <div class='subheading'><h2>${{ item.value }}m NZD</h2></div> -->
          </div>
        </div>
        <div v-if="logo_only && item.villagelogo">
          <div class="details-wrapper" :href="item.link">
          <img :src="item.villagelogo"/>
          </div>
        </div>
      </div>
      <img :src="item.img" />
      <div class="view-project" v-bind:style="{ background: backgroundColor(item) }">
        <a :href="item.link">{{logo_only ? "View Village" : "View Project"}}</a>
      </div>
      </a>
      </div>
  	</div>
    <div class="loading-screen" v-else>
      <img class="loading-gif" src="@/assets/200.gif" />
    </div>
</div>
</template>

<script>
export default {
  name: 'Portfolio',
  props: {
    items: {
      type: Array
    },
    logo_only: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedIndex: 0,
      selectedItem: null,
      viewWidth: 0,
      isMouseOver: false,
      animationRunning: true
    }
  },
  created() {
    document.wb_config = (document.wb_config ? document.wb_config : {})

    if (process.env.NODE_ENV == 'development') {
      document.wb_config.show_completed = true
      document.wb_config.show_current = true
      document.wb_config.show_retirement = true
    }
  },
  computed:{
    offsetIndex() {
      var itemsOnPage = 1
      if (this.viewWidth >= 981) {
        itemsOnPage = 5
      }
      else if (this.viewWidth >= 768) {
        itemsOnPage = 3
      }

      if (this.selectedIndex + (itemsOnPage / 2) > this.items.length) {
        return this.items.length - itemsOnPage
      }

      return Math.round(this.selectedIndex - (itemsOnPage / 2))
    },
    eventWidth() {
      const vw = this.viewWidth;

      if (vw >= 981) {
        return '100vW / 5'
      }
      else if (vw >= 768) {
        return '100vW / 3'
      }

      return '90vW'
    },
    translationTransform() {
      var buffer = 0
      if (this.eventWidth == "90vW") {
        buffer = 5
        if (this.selectedIndex == this.items.length - 1) {
          buffer = 5
        }
        else if (this.selectedIndex > 0) {
          buffer = 5
        }
      }

      var idx = this.offsetIndex
      if (idx < 0) {
        idx = 0
      }


      return `translateX( calc((-${idx} * (${this.eventWidth}) + ${buffer}vw)))`
    },
    orderedItems() {
      return this.items
        .sort((a,b)=>{
          if (!this.$root.showcompleted){
          return a.date < b.date ? 1 : -1
          } else {
            return a.date > b.date ? -1 : 1
          }
        })
    }
  },
  methods: {
    backgroundColor(item){
      if (this.logo_only){
        return item.custcolor
      } 
      return '#484742'
    },
    moveLeft() {
      this.animationRunning = false
      if (this.selectedIndex < this.items.length - 1) {
        this.selectedIndex++
      }
    },
    moveRight() {
      this.animationRunning = false
      if (this.selectedIndex > 0){
        this.selectedIndex--
      }
    },
    
  },
}
</script>

<style scoped lang="scss">
.project{
  width: 33.333%;
  @media (max-width: 786px){
    width: 100%;
  }
  @media (min-width: 787px) and (max-width: 980px){
    width: 50%;
    h2{
      font-size: 20px;
    }
    h4{
      font-size: 14px;
    }
  }
  
  display:inline-block;
  position:relative;
  &:hover{
    cursor: pointer;
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        z-index: 4;
      }
    .view-project{
      background: #231f20;
    }
    .details-wrapper{
      opacity: 1;
      transition: 0.4s ease-in-out;
    }
  }
}
.project img{
  width: 100%;
}
.view-project{
  text-align: center;
  position: absolute;
  background: #484742;
  bottom: 0;
  width: 100%;
  z-index: 4;
  color: #ffffff;
  padding: 16px 0px 16px 0px;
  text-transform: uppercase;
  letter-spacing:1px;
  &:hover{
    background: #231f20;
    transition: 0.4s ease-in-out;
  }
  a{
    color:#ffffff;
    text-decoration: none;
  }
}
.details-wrapper{
  position: absolute;
  top: calc(50% - 50px);
  left: 50%;
  transform: translate(-50%, calc(-50% + 25px));
  z-index: 5;
  text-align: center;
  opacity: 0;
  color: #ffffff;
}
.loading-screen{
  width:100%;
  text-align: center;
}
.loading-gif{
  margin: 30px auto;
}
</style>
