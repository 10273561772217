import Vue from 'vue'
import App from './App.vue'
import VueTouch from 'vue-touch';

Vue.use(VueTouch);
VueTouch.config.swipe = {
  direction: 'horizontal'
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
