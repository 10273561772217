<template>
  <div id="app">
    <div v-if="showcompleted">
      <h1 class="portfolio-title" id="completed-projects">Completed Projects</h1>
      <Portfolio :items="completed" />
    </div>
    <div v-if="showcurrent">
      <h1 class="portfolio-title" id="current-projects">Current Projects</h1>
      <Portfolio :items="current" />
    </div>
    <div v-if="showretirement">
      <h1 class="portfolio-title" id="current-retirement-projects">Current Sanderson Group Retirement Villages</h1>
      <p class="portfolio-description">The Sanderson Group currently own and operate two large-scale retirement villages in the Waikato and the Bay of Plenty</p>
      <Portfolio :items="currentVillage" :logo_only="true" />
      <h1 class="portfolio-title" id="retirement-projects">Completed Retirement Village Projects</h1>
      <p class="portfolio-description">The following retirement villages were developed and operated by the Sanderson Group but have since been sold</p>
      <Portfolio :items="retirement" />
    </div>
  </div>
</template>

<script>
import Portfolio from './components/Portfolio.vue'
import axios from 'axios'

export default {
  name: 'app',
  components: {
    Portfolio
  },
  data() {
    return {
      items: [],
      loadcomplete: false
    }
  },
  computed:{
    showcompleted(){
      return document?.wb_config?.show_completed
    },
    showcurrent(){
      return document?.wb_config?.show_current
    },
    showretirement(){
      return document?.wb_config?.show_retirement
    },
    loadeditems(){
      return this.loadcomplete ? this.items : []
    },
    current(){
      return this.loadeditems.filter(i => i.categories.includes(9))
    },
    retirement(){
      return this.loadeditems.filter(i => i.categories.includes(11))
    },
    completed(){
      return this.loadeditems.filter(i => i.categories.includes(8))
    },
    currentVillage(){
      return this.loadeditems.filter(i => i.tags.includes(15))
    }
  },
  methods:{
    requestItems(pageNo) {
      axios.request({
        url: `${process.env.VUE_APP_BASE_URL}wp-json/wp/v2/timeline_event?_embed&page=${pageNo}`
      }).then(resp => {
        const toAdd = resp.data
          .filter(d => {
            return d.acf
              && d.acf.event_year
              && d.acf.project_value
              // && d.acf.custom_colour
              // && d.acf.village_logo
              && d._embedded && d._embedded["wp:featuredmedia"]
              && d._embedded["wp:featuredmedia"].length
              && d._embedded["wp:featuredmedia"][0].source_url
              && d.title
              && d.title.rendered
              && d.link
              && d.categories
              && d.tags
          })
          .map(d => {
            return {
              id: d.id,
              date: d.acf.event_year,
              img: d._embedded["wp:featuredmedia"][0].source_url,
              title: d.title.rendered,
              value: d.acf.project_value,
              link: d.acf.alt_link ? d.acf.alt_link : d.link,
              categories: d.categories,
              tags: d.tags,
              custcolor: d.acf.custom_colour,
              villagelogo: d.acf.village_logo
            }
          })
        
        toAdd.forEach(post => {
          this.items.push(post)
        });

        if (toAdd.length == 10) {
          this.requestItems(pageNo + 1)
        } else{
          this.loadcomplete = true
        }
      })
      .catch(err => {
        if (err?.response?.status == 400 && this.items.length) {
          // happens if the last page was full but there is no next page
          this.loadcomplete = true
        }
        else {
          console.log(err)
        }
      })
    }
  },
  created() {
    document.wb_config = (document.wb_config ? document.wb_config : {})

    if (process.env.NODE_ENV == 'development') {
      document.wb_config.show_completed = true
      document.wb_config.show_current = true
      document.wb_config.show_retirement = true
    }
  },
  mounted(){
    this.requestItems(1);
  },
}
</script>

<style lang="scss">
body {
  margin: 0;
}
h1{
  text-align: center;
  clear:both;
  padding: 60px 10px 10px 10px;
}
.portfolio-description{
 text-align: center;
 max-width: 550px;
 margin: 0 auto;
 padding: 0px 10px 50px 10px;
}
</style>
